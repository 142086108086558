import RednessTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/redness/RednessTemplate';

export default {
  title: 'Steps/Redness/Redness/RednessTemplate'
};

const createStory = props => () => ({
  components: { RednessTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <redness-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  skinRedness: 'no'
});
